<template>
  <div>
    <Page :sections="sections" />
    <v-container
      v-if="showFooterBtn"
      class="inner-container inner-container--content"
    >

      <div class="text-center">
        <Main-btn
          class="mt-md-10 mt-5"
          v-if="footerBtn_text"
          checkLink
          :targetBlank="footerBtn_targetBlank"
          linkOut
          :to="footerBtn_url"
          >{{ footerBtn_text }}</Main-btn
        >
      </div>
    </v-container>
  </div>
</template>

<script lang="babel" type="text/babel">
import Page from '@/components/dynamic/page.vue'
import detailMixins from "@/components/page/detail.js";
export default {
  mixins: [detailMixins],
  components: {
    Page
  },
  data: () => ({
    pageData: null,
  }),
  computed: {
    sections() {
      if (!this.pageData) return [];
      const sections = _cloneDeep(this.pageData.sections)
      this.handleCard3(sections);
      return sections
    },
    footerButton() {
      if(!this.pageData) return null
      if(!this.pageData.meta) return null
      return this.pageData.meta.footerButton
    },
    footerBtn_targetBlank() {
      if(!this.footerButton) return false
      return !!this.footerButton.targetBlank
    },
    footerBtn_url() {
      if(!this.footerButton) return ''
      return this.$helper.modifyUrl(this.footerButton.url, this);
    },
    footerBtn_text() {
      if(!this.footerButton) return ''
      return this.footerButton.text
    },
    showFooterBtn() {
      return !!this.footerBtn_text
    },
    api() {
      if(this.hasPreviewLink) return this.$api.page.admin.preview
      return this.$api.page.public.read
    },
    pageBreadcrumbs() {
      let breadcrumbs = _get(this.pageData, 'breadcrumbs')
      if(!Array.isArray(breadcrumbs)) return null
      breadcrumbs = breadcrumbs.filter(breadcrumb => {
        if(!Array.isArray(breadcrumb.items)) return false
        if(breadcrumb.items.length == 0) return false
        return true
      })
      if(breadcrumbs.length == 0) return null
      // 在item都加上route resolve path
      const result = breadcrumbs.map(breadcrumb => {
        breadcrumb.items = breadcrumb.items.map(item => {
          if(item.code) {
            item.path = this.getBreadcrumbPageItemPath(item)
          }
          return item
        })

        return breadcrumb
      })
      return result
    },
    from() {
      return this.$route.query.from
    },
    matchBreadcrumbRule() {
      if(!this.pageBreadcrumbs) return null
      const matchBreadcrumb = this.pageBreadcrumbs.find(breadcrumb => {
        const lastItem = _last(breadcrumb.items)
        if(!lastItem.path) return false
        return lastItem.path == this.from
      })

      // 找不到符合的就抓第一組的
      if(!matchBreadcrumb) {
        return this.pageBreadcrumbs[0]
      }

      return matchBreadcrumb
    },
    breadcrumb() {
      if (!this.pageData) return [];
      const currentPage = { title: this.pageData.name }
      if(!this.matchBreadcrumbRule) {
        return []
      }

      if(!Array.isArray(this.matchBreadcrumbRule.items)) {
        return []
      }

      if(this.matchBreadcrumbRule.items.length == 0) {
        return [currentPage]
      }

      const breadcrumbs = this.matchBreadcrumbRule.items.map(item => ({
        title: item.title,
        to: {
          name: 'page',
          params: { code: item.code },
        }
      }))
      return [
        ...breadcrumbs,
        currentPage,
      ];
    },
  },
  methods: {
    handleCard3(sections) {
      const collect = this.collect(sections)
      this.setMark(collect)
      // console.log(collect)
    },
    collect(sections) {
      const collect = []
      let arr = []
      let flag = false
      for(let i = 0; i<sections.length; i++) {
        const current = sections[i]
 
        if(current.type == 'card' && current.style == 3) {
          arr.push(current)
          flag = true
          if(i == sections.length-1) {
            collect.push(arr)
          }
          continue
        }

        if(flag == true) {
          collect.push(arr)
          arr = []
          flag = false
        }

      }
      return collect
    },
    setMark(collect) {
      for(let i=0; i<collect.length;i++) {
        const array = collect[i]
        if(array.length == 1) continue
        if(array.length == 2) {
          array[0].location = 'first'
          array[1].location = 'last'
          continue
        }

        for(let j=0; j<array.length;j++) {
          const section = array[j]
          if(j == 0) {
            section.location = 'first'
            continue
          }
          if(j == array.length - 1) {
            section.location = 'last'
            continue
          }
          section.location = 'middle'
        }
      }
    },
    getBreadcrumbPageItemPath(item) {
      let path = null
      try {
        path = this.$router.resolve({
          name: 'page',
          params: { code: item.code },
        }).href
      } catch (error) {
        console.warn('getBreadcrumbPageItemPath', error)
        console.warn('item', item)
      }
      return path
    },
    afterGetPageData() {
      this.setAnchor(this.anchorList)
      this.setLogoColor()
    },
    async setLogoColor() {
      if(_isEmpty(this.sections)) return
      const first = this.sections[0]
      if(first.type === 'banner') {
        await this.$helper.delay(0.5)
        const name = first.type === 'banner' ? 'ecvBanner' : 'card-style3-owl'
        const height = $(`.${name}`)[0].offsetHeight
        this.$store.commit('base/setLogoChangeBase', height)
        this.$root.$emit('onNavStatus')
        return
      }
      // 其他條件logo就變有顏色的版本
      this.$store.commit('base/setLogoChangeBase', 0)
      this.$root.$emit('onNavStatus')
    },
  },
}
</script>

<style lang="sass" type="text/sass">
</style>
